import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon
} from '@coreui/icons'

export const icons = Object.assign({}, {
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon
})
